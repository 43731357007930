var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper",class:( _obj = {
    'page-wrapper--alerts': _vm.isAlertVisible,
    'page-wrapper--alerts-expiration': _vm.isExpirationAlertVisible
  }, _obj[("page-wrapper--alerts-" + _vm.numberOfAlerts)] = _vm.isAlertVisible, _obj )},[_c('div',{staticClass:"page-wrapper__alerts"},[(_vm.isExpirationAlertVisible)?_c('SlAlertLine',{staticClass:"expiration-alert",attrs:{"type":"error","accent":_vm.$t('Web.LicenseDetails.AccountSuspension'),"column":""},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t('Web.LicenseDetails.OutstandingInvoicesReminder', { 1: _vm.invoiceDueLocaleDate })))]),_c('i18n',{attrs:{"path":"Web.LicenseDetails.PaymentConfirmationInstruction","tag":"p"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"href":("mailto:" + _vm.SUPPORT_EMAIL),"target":"_blank","color":"red"}},[_vm._v(" "+_vm._s(_vm.SUPPORT_EMAIL)+" ")])]},proxy:true}],null,false,3259765148)}),(_vm.$sl_isUserAdmin)?_c('SlLink',{staticClass:"mt-8",attrs:{"href":{
            name: _vm.routeNames.SUBSCRIPTION
          },"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('Web.LicenseDetails.ReviewSubscription'))+" ")]):_vm._e()]},proxy:true}],null,false,312132858)}):_vm._e(),(_vm.isConstrainedPlanOutdated)?_c('SlAlertLine',{attrs:{"type":"warn","accent":_vm.$t('Web.Settings.ConstrainedPlanOutdated'),"text":_vm.$t('Web.Settings.ConstrainedPlanOutdatedWarning')}}):_vm._e(),(_vm.isOverdated)?_c('SlAlertLine',{attrs:{"type":"warn"},scopedSlots:_vm._u([{key:"accent",fn:function(){return [_c('i18n',{attrs:{"path":"Web.UpdateData.PleaseUpdate"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"href":{
                name: _vm.routeNames.SETTINGS,
                hash: ("#" + (_vm.settingsTabKeys.PROJECT))
              },"color":"yellow"}},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.acSettings'))+" ")])]},proxy:true}],null,false,2784929805)})]},proxy:true}],null,false,2871111825)}):_vm._e(),(_vm.frozenDate)?_c('SlAlertLine',{attrs:{"type":"warn","accent":_vm.$t('Web.FreezeCalc.AlertLabel', { 1: _vm.frozenDate })},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('SlLink',{attrs:{"color":"yellow"},on:{"click":function($event){$event.preventDefault();return _vm.handleUnfreezeSimulation($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Web.FreezeCalc.Unfreeze'))+" ")])]},proxy:true}],null,false,3535695100)}):_vm._e()],1),(_vm.$slots.modal)?[_vm._t("modal")]:_vm._e(),(_vm.$slots.toolbar && !_vm.loading)?[_vm._t("toolbar")]:_vm._e(),(!_vm.loading)?[_vm._t("default")]:_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading)?_c('div',{staticClass:"page-loader"},[(_vm.$slots.loader)?_vm._t("loader"):_vm._e()],2):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }